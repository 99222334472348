<template>
    <div class="page ticketsSubmit">
        <div class="body">
            <div class="image">
                <img :src="productItem.goods_image" :alt="productItem.goods_image"/>
            </div>
            <p class="title">景点门票</p>
            <div class="form">
                <div class="desc">请填写预约人1信息(成人)</div>
                <div class="group">
                    <div class="label">姓名</div>
                    <div class="input">
                        <input type="text" placeholder="预约顾客姓名" v-model="name"/>
                    </div>
                </div>
                <div class="group">
                    <div class="label">电话</div>
                    <div class="input">
                        <input type="number" maxlength="11" placeholder="预约顾客手机号码" v-model="phone"/>
                    </div>
                </div>
                <div class="group">
                    <div class="label">身份证</div>
                    <div class="input">
                        <input type="text" maxlength="18" placeholder="预约顾客证件号码" v-model="idcard"/>
                    </div>
                </div>
                <div class="desc">请填写预约人2信息(成人)</div>
                <div class="group">
                    <div class="label">姓名</div>
                    <div class="input">
                        <input type="text" placeholder="预约顾客姓名" v-model="name1"/>
                    </div>
                </div>
                <div class="group">
                    <div class="label">电话</div>
                    <div class="input">
                        <input type="number" maxlength="11" placeholder="预约顾客手机号码" v-model="phone1"/>
                    </div>
                </div>
                <div class="group">
                    <div class="label">身份证</div>
                    <div class="input">
                        <input type="text" maxlength="18" placeholder="预约顾客证件号码" v-model="idcard1"/>
                    </div>
                </div>
                <div class="note">若无第二出行人，预约人2信息可不填写</div>
                <div class="desc">请填写预约人3信息(婴儿或儿童)</div>
                <div class="group">
                    <div class="label">姓名</div>
                    <div class="input">
                        <input type="text" placeholder="预约顾客姓名" v-model="name2"/>
                    </div>
                </div>
                <div class="group">
                    <div class="label">身份证</div>
                    <div class="input">
                        <input type="text" maxlength="18" placeholder="预约顾客证件号码" v-model="idcard2"/>
                    </div>
                </div>
                <div class="note">若无儿童出行人，预约人3信息可不填写，若无婴儿证件请致电景点咨询需预留信息</div>
                <div class="desc">请选择出行时间</div>
                <div class="group">
                    <div class="label">时间</div>
                    <div class="input">
                        <select class="item" v-model="time">
                            <option disabled value="">请选择出行时间</option>
                            <option v-for="item in timeList" :value="item">{{item}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="remark">
                <span>备注</span>
                <span>{{productItem.elaboration2||'无'}}</span>
            </div>
        </div>
        <div class="footer">
            <span @click="submitCreateAddress">提交</span>
        </div>
    </div>
</template>

<script>
    import {getUsedate, submitProduct} from "../api/data";
    import _ from "lodash";
    import {Toast} from "vant";

    export default {
        name: "MatterSubmit",
        data: function () {
            return {
                timeList: [],//所有可选时间
                productItem: null,
                name: null,
                phone: null,
                idcard: null,
                name1: null,
                phone1: null,
                idcard1: null,
                name2: null,
                idcard2: null,
                time: "",
            }
        },
        async beforeMount() {
            let productItem = localStorage.getItem("productItem");
            this.productItem = JSON.parse(productItem)
            console.log('productItem=', this.productItem)
            let res = await getUsedate({});
            if (_.toInteger(res.code) === 1) {
                this.timeList = res.data.data
            } else {
                Toast.fail(res.msg);
            }
        },
        methods: {
            // 提交信息
            async submitCreateAddress() {
                let cardReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                let phoneReg = /^1[3456789]\d{9}$/;
                if (this.name === '' || this.name === null) {
                    Toast.fail('请填写顾客姓名');
                    return false
                } else if (this.phone === '' || this.phone === null) {
                    Toast.fail('请填写顾客手机号码');
                    return false
                } else if (this.phone && !phoneReg.test(this.phone)) {
                    Toast.fail('请填写正确顾客手机号码');
                    return false
                } else if (this.idcard === '' || this.idcard === null) {
                    Toast.fail('请填写顾客身份证号码');
                    return false
                } else if (this.idcard && !cardReg.test(this.idcard)) {
                    Toast.fail('请填写正确顾客1身份证号码');
                    return false
                } else if (this.phone1 && !phoneReg.test(this.phone1)) {
                    Toast.fail('请填写正确顾客2手机号码');
                    return false
                } else if (this.idcard1 && !cardReg.test(this.idcard1)) {
                    Toast.fail('请填写正确顾客2身份证号码');
                    return false
                } else if (this.idcard2 && !cardReg.test(this.idcard2)) {
                    Toast.fail('请填写正确顾客3身份证号码');
                    return false
                } else if (this.time === '' || this.time === null) {
                    Toast.fail('请选择出行时间');
                    return false
                }
                let param = {
                    id: this.productItem.id,
                    goods_id: this.productItem.goods_id,
                    name: this.name,
                    phone: this.phone,
                    time: this.time,
                }
                if (this.name1 !== '' || this.name1 !== null) {
                    param.name1 = this.name1;
                }
                if (this.phone1 !== '' || this.phone1 !== null) {
                    param.phone1 = this.phone1;
                }
                if (this.idcard1 !== '' || this.idcard1 !== null) {
                    param.idcard1 = this.idcard1;
                }
                if (this.name2 !== '' || this.name2 !== null) {
                    param.name2 = this.name2;
                }
                if (this.idcard2 !== '' || this.idcard2 !== null) {
                    param.idcard2 = this.idcard2;
                }
                let res = await submitProduct(param);
                // let res = {code: 1, msg: "请求成功", time: "1625894423", data: {order_on: "2021071013202355991021", order_id: "72"}};
                if (_.toInteger(res.code) === 1) {
                    await this.$router.push({name: 'Orderdetail', query: {orderId: res.data.order_id}});
                } else {
                    Toast.fail(res.msg);
                }

            }
        }
    }
</script>

<style scoped>

    .ticketsSubmit {
        display: flex;
        flex-direction: column;
        width: 690px;
        flex: 1;
        overflow-x: hidden;
        justify-content: flex-start;
        align-items: center;
        padding: 24px 24px 148px 24px;
        box-sizing: border-box;
        overflow-y: auto;
    }

    .ticketsSubmit .body {
        width: 642px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 20px;
    }

    .ticketsSubmit .body .image {
        width: 562px;
        height: auto;
        min-height: 582px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .ticketsSubmit .body .image img {
        width: 100%;
        height: auto;
    }

    .ticketsSubmit .body .title {
        width: 642px;
        height: 50px;
        font-size: 36px;
        font-weight: bold;
        line-height: 50px;
        color: #212429;
        text-align: center;
        margin: 59px auto 39px;
    }

    .ticketsSubmit .body .form {
        width: 642px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: #FFFFFF;
        border-radius: 16px;
        padding: 30px;
        box-sizing: border-box;
    }

    .ticketsSubmit .body .form .desc {
        margin-top: 44px;
        margin-bottom: 24px;
        width: 100%;
        height: 42px;
        font-size: 30px;
        font-weight: bold;
        line-height: 42px;
        color: #A05923;
        text-align: center;
    }

    .ticketsSubmit .body .form .group {
        box-sizing: border-box;
        width: 100%;
        border: 2px solid #222529;
        min-height: 75px;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
        padding: 23px;
    }

    .ticketsSubmit .body .form .group .label {
        font-size: 25px;
        color: #212428;
        font-weight: 500;
        letter-spacing: 10px;
    }

    .ticketsSubmit .body .form .group .input {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 17px;
    }

    .ticketsSubmit .body .form .group .input input, .ticketsSubmit .body .form .group .input select {
        display: flex;
        flex: 1;
        border: 0;
        background: #FFFFFF;
    }

    .ticketsSubmit .body .form .group .input select::-ms-expand {
        display: none;
    }

    .ticketsSubmit .body .form .group .input input::-webkit-input-placeholder, .ticketsSubmit .form .group .input input::-moz-placeholder, .ticketsSubmit .form .group .input input::-ms-input-placeholder {
        color: #C7C7C7;
        font-weight: 400;
        font-size: 30px;
    }

    .ticketsSubmit .body .form .note {
        margin-top: 30px;
        padding: 0 20px;
        font-size: 24px;
        font-weight: 400;
        line-height: 34px;
        color: #FF3313;
    }

    .ticketsSubmit .body .remark {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 48px 0;
    }

    .ticketsSubmit .body .remark span:first-child {
        font-size: 28px;
        font-weight: bold;
        line-height: 34px;
        color: #272A2F;
        margin-bottom: 10px;
    }

    .ticketsSubmit .body .remark span:last-child {
        font-size: 24px;
        font-weight: 400;
        line-height: 34px;
        color: #272A2F;
    }

    .ticketsSubmit .footer {
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        width: 690px;
        height: 120px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;
    }

    .ticketsSubmit .footer span {
        width: 100%;
        height: 80px;
        border: 1px solid #707070;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        border-radius: 40px;
        font-size: 30px;
        font-weight: bold;
        color: #E2BE93;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
